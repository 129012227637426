<template>
  <div class="tracks" id="tracks">
    <HeaderCommon ref="header" title="足跡">
      <template v-slot:headerButton>
        <ButtonBack />
      </template>
    </HeaderCommon>
    <div id="content-scroll" class="content-scroll">
      <div class="tracks__list" id="tracks-content">
        <div class="_content">
          <template v-if="isLoading">
            <AppLoading />
          </template>
          <template v-else>
            <template v-if="tracks.length > 0">
              <TracksItem
                v-for="(item, key) of tracks"
                :key="key"
                :data="item"
                :tabindex="key"
              />
            </template>
            <template v-else>
              <p>現在足跡はありません。</p>
            </template>
          </template>

          <scroll-loader
            :loader-method="getListTrack"
            :loader-disable="disable"
          >
            <AppLoading v-if="page !== 1" />
            <p v-else></p>
          </scroll-loader>
        </div>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import ButtonBack from "@/views/Common/ButtonBack.vue";
import HeaderCommon from "@/views/Common/HeaderCommon";
import TracksItem from "./TracksItem";
import CardFooter from "@/components/CardFooter";
import { mapGetters } from "vuex";

export default {
  name: "Tracks",
  components: {
    ButtonBack,
    TracksItem,
    HeaderCommon,
    CardFooter
  },
  metaInfo() {
    return {
      title: "足跡",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      tracks: "auth/tracks",
      isLoading: "common/isLoading"
    })
  },
  data() {
    return {
      headerTitle: {
        text: "足跡",
        isShowTitle: true,
        isShowLogo: false
      },
      page: 1,
      perpage: 12,
      params: [],
      disable: false
    };
  },
  mounted: function() {
    let touchStart, touchEnd;
    let self = this;
    let elements = document.getElementById("tracks");
    elements.addEventListener("touchstart", swipe);
    elements.addEventListener("touchend", swipe);
    elements.addEventListener("touchmove", swipe);

    function swipe(event) {
      if (event.type == "touchstart") {
        touchStart = event.targetTouches[0].clientY;
      }
      if (event.type == "touchmove") {
        touchEnd = event.targetTouches[0].clientY;
      }
      if (
        document.getElementById("tracks-content").getBoundingClientRect().top >=
        40
      )
        if (event.type == "touchend") {
          if (touchEnd - touchStart >= 150) {
            self.$root.$refs.loading.start();
            window.location.reload();
          }
        }
      if (event.type == "touchend") {
        touchEnd = 0;
      }
    }
  },
  async created() {
    await this.$store.dispatch("common/setIsLoading", true);
    let params = {
      page: this.page,
      perpage: this.perpage,
      commit_type: "SET_TRACK"
    };
    await this.requestData(params);
    await this.$store.dispatch("common/setIsLoading", false);
  },
  methods: {
    async requestData(params) {
      await this.$store.dispatch("auth/getTrackList", params).then(response => {
        let total = response.data.total;
        this.disable = total < this.page * this.perpage;
        this.page++;
        if (this.page > 1) {
          this.params = params;
          this.params.page = this.page;
          this.params.commit_type = "SET_TRACK_LOAD_MORE";
        }
      });
    },

    // loadmore data
    async getListTrack() {
      if (this.page === 1) return;
      await this.requestData(this.params);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 80px;
  // height: calc(100vh - 108px);
}
.tracks {
  // background-color: #f5f5f5;
  &__list {
    margin: 0 auto;
    padding: 16px 19px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .header {
    .btn-back {
      position: absolute;
      left: 19px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .tracks {
    &__list {
      padding: 35px 19px;
      padding-bottom: 100px;
      ._content {
        margin: 0 auto;
        max-width: 741px;
      }
    }
  }
}
</style>
