<template>
  <div
    class="tracks__item d-flex justify-content-between"
    :class="data.is_read === 0 ? 'new' : ''"
    :tabindex="tabindex"
    @blur="showOption = false"
  >
    <div class="d-flex align-items-center _info">
      <div @click.prevent="redirectProfile(data.user_id)">
        <AvatarBox
          :imgUrl="imgUrl(data.image_path)"
          imgSize="46px"
          :imgAlt="data.nickname"
        />
      </div>
      <div class="f-w3 _content txt-left">
        <span
          class="_name f-w6"
          @click.prevent="redirectProfile(data.user_id)"
          >{{ data.nickname }}</span
        >さんから足あとがつきました
      </div>
    </div>

    <div class="d-flex flex-column align-items-end _other">
      <span class="_time f-w3">{{ getTime(data.updated_at) }}</span>
      <div
        v-if="data.is_block === 0"
        class="_more d-flex"
        @click="showOption = !showOption"
      >
        <img
          v-if="showOption"
          :src="require('@/assets/image/dot-icon-active.svg')"
          alt="menu"
        />
        <img v-else :src="require('@/assets/image/dot-icon.svg')" alt="menu" />
      </div>
      <div
        class="options d-flex flex-column"
        :class="showOption ? 'active' : ''"
        v-if="data.is_block === 0"
      >
        <div class="options__bg" @click="showOption = !showOption"></div>
        <div class="options__content" :id="'option_' + tabindex">
          <div
            class="_item d-flex align-items-center"
            @click="actionToUser('/report')"
          >
            <img
              :src="require('@/assets/image/report-icon.svg')"
              alt="report user /"
            />
            <span class="f-w3">通報</span>
          </div>
          <div
            class="_item d-flex align-items-center"
            @click="actionToUser('/block')"
          >
            <img
              :src="require('@/assets/image/block.svg')"
              alt="block user /"
            />
            <span class="block f-w3">ブロック</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarBox from "@/views/Common/AvatarBox.vue";
import service from "@/utils/axios";
import { timeSimple } from "@/utils/timeFormat";
import { USER_URL } from "@/api/api";
import imageMixin from "@/mixins/imageMixin";
import { mapGetters } from "vuex";

export default {
  name: "TracksItem",
  mixins: [imageMixin],
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  props: {
    data: {
      type: Object,
      require: true
    },
    tabindex: {
      type: Number
    }
  },
  data() {
    return {
      showOption: false
    };
  },
  components: {
    AvatarBox
  },
  methods: {
    redirectProfile(userId) {
      if (this.user.sex == 1) {
        this.$router.push({
          path: `/profile/${userId}`
        });
      } else {
        this.$router.push({
          path: `/cast/profile/${userId}`
        });
      }
    },
    getTime(time) {
      return timeSimple(time);
    },
    async actionToUser(type) {
      this.$root.$refs.loading.start();

      // for report
      let data = { user_id: this.data.user_id };
      let msg = "通報しました。";

      // for block
      if (type === "/block") {
        data = { user_block_id: this.data.user_id };
        msg = "ブロックしました。";
      }

      await service({
        url: USER_URL + type,
        method: "POST",
        data
      })
        .then(() => {
          this.$toast(msg, "通知", "success");
          // this.$store.dispatch("auth/updateTrack", {
          //   user_id: this.data.user_id
          // });
          if (type === "/block") {
            this.data.is_block = 1;
          }
          this.showOption = false;
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.showOption = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

*:focus,
*:active {
  box-shadow: none;
  outline: none;
}

.tracks {
  &__item {
    position: relative;
    min-height: 70px;
    background-color: #f5f5f5;
    padding: 12px 18px;
    border-radius: 10px;
    .avatar-box {
      margin-right: 20px;
    }

    ._info {
      width: calc(100% - 70px);
    }

    ._content {
      width: calc(100% - 66px);
      font-size: 14px;
    }

    ._name {
      color: #464d77;
      cursor: pointer;
    }

    ._time {
      color: #818181;
      margin-bottom: 10px;
      font-size: 10px;
    }

    ._other {
      width: 70px;
    }

    ._more {
      cursor: pointer;
      img {
        height: 4px;
        width: 18px;
      }
    }

    &.new {
      border-radius: 10px;
      background-color: #ecf5ff;
      border: 1px solid #0178fd;
    }

    .block {
      color: #f14e4e;
    }
  }
}

.options {
  ._item {
    height: 50px;
    padding: 0 19px;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }

    &:nth-child(1) {
      border-bottom: 1px solid #d9d9d9;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .options {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    transition: ease 0.5s;
    display: flex;
    align-items: flex-end;
    z-index: 100;
    &.active {
      visibility: visible;

      .options__content {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &__content {
      background-color: #fff;
      border-radius: 20px 20px 0 0;
      opacity: 0;
      transition: ease 0.5s;
      transform: translateY(50px);
      width: 100%;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 100;
    }

    &__bg {
      width: 100%;
      height: calc(100vh - 100px);
    }
  }
}

@media only screen and (min-width: 1200px) {
  ._dot {
    @include borderRadius(6px, 6px, 50%);
  }

  .tracks {
    &__item {
      padding: 15px;
      min-height: 98px;
      .avatar-box {
        width: 68px !important;
        height: 68px !important;
      }

      ._info {
        width: calc(100% - 80px);
      }

      ._other {
        width: 80px;
        position: relative;
      }

      ._time {
        font-size: 12px;
        margin-bottom: 16px;
      }

      ._more {
        img {
          height: 6px;
          width: 23px;
        }
      }

      ._content {
        font-size: 16px;
        width: calc(100% - 88px);
      }

      .block {
        color: #f14e4e;
      }
    }
  }

  .options {
    position: absolute;
    width: 218px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    transition: ease 0.2s;
    transform-origin: top right;
    transform: scale(0.6);
    visibility: hidden;
    opacity: 0;
    top: 50px;
    box-shadow: 0 0 15px #00000029;
    z-index: 2;
    ._item {
      padding: 0 19px;
    }

    &.active {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
